@import 'variables';

h1, h2, h3, h4, h5 , h6, small{
  font-weight: 400;
}

h1 {
  font-size: 60px;
  line-height: 40px;
  font-weight: 400;
}

h2 {
  font-size: 50px;
  line-height: 63px;
}

h3 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
}

h4 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 700;
}

h5 {
   font-size: 28px;
   line-height: 40px;
   font-weight: 700;
 }

h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
}

p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}

small {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400;
}

strong{
  font-weight: bold;
}

@media (max-width: $sm-size) {

  h1, h2{
  }

  h1 {
    font-size: 38px;
    line-height: 49.4px;
    font-weight: bold;
  }

  h2 {
    font-size: 36px;
    line-height: 55px;
  }

  h3 {
    font-size: 30px;
    line-height: 50px;
  }

  h4 {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold;
  }
}
