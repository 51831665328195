/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block; }

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  font: inherit; }

:focus {
  outline: none; }

/* Uncomment and set these variables to customize the grid. */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem; }

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 1 auto;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse; }

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 1rem;
  padding-left: 1rem; }

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center; }

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start; }

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center; }

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end; }

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around; }

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between; }

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1; }

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

@media only screen and (min-width: 62em) {
  .container {
    width: 61rem; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 71rem; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 1rem;
    padding-left: 1rem; }
  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center; }
  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start; }
  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center; }
  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end; }
  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around; }
  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1; }
  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1; } }

.container {
  width: 100%;
  max-width: 1224px; }

.container-sm {
  max-width: 760px; }

.container-fluid {
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column; }

.gutterless {
  padding-left: 0;
  padding-right: 0; }
  .gutterless > .row {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }

[class^='col-xs'],
[class^='col-sm'],
[class^='col-md'],
[class^='col-lg'] {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex; }

h1, h2, h3, h4, h5, h6, small {
  font-weight: 400; }

h1 {
  font-size: 60px;
  line-height: 40px;
  font-weight: 400; }

h2 {
  font-size: 50px;
  line-height: 63px; }

h3 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 400; }

h4 {
  font-size: 35px;
  line-height: 50px;
  font-weight: 700; }

h5 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 700; }

h6 {
  font-size: 18px;
  font-weight: 400;
  line-height: 32px; }

p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 400; }

small {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 400; }

strong {
  font-weight: bold; }

@media (max-width: 48em) {
  h1 {
    font-size: 38px;
    line-height: 49.4px;
    font-weight: bold; }
  h2 {
    font-size: 36px;
    line-height: 55px; }
  h3 {
    font-size: 30px;
    line-height: 50px; }
  h4 {
    font-size: 30px;
    line-height: 40px;
    font-weight: bold; } }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

* {
  box-sizing: border-box; }

.page-wrapper {
  min-height: calc(100vh - 370px); }
  .page-wrapper > * {
    overflow-x: hidden; }

.loading img {
  height: 120px;
  width: 105px; }

.btn {
  transition: all .4s ease;
  height: 100%;
  width: 100%;
  font-family: "proxima-nova", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 10px 30px 0 rgba(28, 29, 29, 0.33);
  border: none; }
  .btn.disabled {
    pointer-events: none; }
  .btn:not(.btn:disabled):hover, .btn:not(.disabled):hover {
    cursor: pointer; }
  .btn.dark {
    background-color: #142428;
    color: #ffffff; }
    .btn.dark:not(:disabled):hover, .btn.dark:not(.disabled):hover {
      background-color: rgba(20, 36, 40, 0.7) !important;
      cursor: pointer; }
    .btn.dark:disabled, .btn.dark.disabled {
      background-color: rgba(20, 36, 40, 0.2); }
  .btn.light {
    background-color: #FFFFFF;
    color: #142428; }
    .btn.light:not(.disabled):hover {
      background-color: rgba(255, 255, 255, 0.8); }
    .btn.light.disabled {
      background-color: rgba(255, 255, 255, 0.25); }
  .btn.blue {
    background-color: #46bed6;
    color: #ffffff;
    border: 1px solid transparent; }
    .btn.blue:not(.disabled):hover {
      background-color: rgba(70, 190, 214, 0.7); }
    .btn.blue.disabled {
      background-color: rgba(70, 190, 214, 0.2); }
  .btn.transparent {
    border: 2px solid #FFFFFF;
    background-color: transparent;
    color: #ffffff; }
    .btn.transparent:not(.disabled):hover {
      background-color: #FFFFFF; }
    .btn.transparent.disabled {
      border: 2px solid rgba(255, 255, 255, 0.2); }
  .btn.transparent-bordered {
    border: 2px solid #142428;
    background-color: transparent;
    color: #ffffff;
    width: auto; }
    .btn.transparent-bordered:not(.disabled):hover {
      background-color: #142428;
      color: #ffffff !important; }
    .btn.transparent-bordered.disabled {
      border: 2px solid rgba(255, 255, 255, 0.2); }
  .btn.bordered {
    border: 1px solid #BFC5D2;
    background-color: transparent;
    color: #8798AD;
    width: auto; }
    .btn.bordered:not(.disabled):hover {
      background-color: #142428;
      color: #ffffff !important; }
    .btn.bordered.disabled {
      border: 2px solid rgba(255, 255, 255, 0.2); }

.ReactModal__Content {
  overflow: hidden !important; }

@media (max-width: 62em) {
  .hidden-xs {
    display: none; }
  .page-wrapper {
    min-height: calc(100vh - 594px); } }

.vertical-align {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
