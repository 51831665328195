@import "../../styles/_variables";
@import '../../styles/_mixins';

.not-implemented-wrapper {
  position: relative;
  height: calc(100vh - 382px);
  .not-implemented-content{
	position: absolute;
	top:50%;
	transform: translateY(-50%);
    width: 100%;
    background-size: cover;
  }

  .title{
    color: #162B24;
    font-size: 35px;
    font-weight: 300;
    line-height: 45px;
    text-shadow: 0 10px 30px 0 rgba(28,29,29,0.33);
    font-family: $proxima-nova;
    max-width: 463px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .message{
    font-family: $nunito;
    font-size: 18px;
    color: rgba(22,43,36,0.5);
    line-height: 33px;
    max-width: 364px;
    margin-left: auto;
    margin-right: auto;
	padding-bottom: 60px;
  }

  a{
	background-color: white;
    border: 2px solid #46BED6;
    padding: 22px 35px;
    margin-top: 50px;
    text-transform: uppercase;
    text-shadow: 0 1px 1px 0 rgba(31,111,43,0.12);
    line-height: 16px;
    font-size: 13px;
    letter-spacing: 2.79px;
    font-weight: bold;
    font-family: $nunito;
    color: #46BED6;
	text-decoration: none;
  }
}



@media (min-width: $md-size) {
  .not-implemented-wrapper {
	min-height: calc(100vh - 181px);
  }
}
@media (max-width: 500px) {
  .not-implemented-wrapper {
	min-height: calc(100vh);
  }
}
