@import '../styles/_variables';
@import '../styles/_mixins';

.site-navbar {
  user-select: none;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  z-index: 100000;
  transition: all .8s ease;

  &.transparentNav{
    background-color: transparent;

	.second-logo{
	  display: none;
	}
  }

  &:not(.transparentNav){
	border-bottom: 1px solid #E5E5E5;
  }

  > .container,
  > .container > .row {
    height: 100%;
  }

  .logo {
    height: 56px;
    width: 172px;
  }

  .second-logo{
	//transition: all .4s ease-in-out;
  }

  .nav-menu-button,
  .i18n {
    display: none;

	span{
	  line-height: 18px;
	}
  }

}

@media (max-width: $md-size) {
  .site-navbar {
	height: 64px;

	&.transparentNav{
	  .nav-menu-button{
		span{
		  background: white;
		}
	  }

	  .logo{
		display: none;
	  }

	  .second-logo{
		margin-left: 170px;
	  }

	}

	.logo{
	  width: 103px;
	}

	.second-logo{
	  margin-left: 30px;
	  width: 53px !important;
	  height: 40px;
	}

	&.open{

	  .second-logo,
	  .logo{
		opacity: 0;
	  }

	  .nav-menu-button{
		span{
		  background: black;
		}
	  }

	  .nav-wrapper{
		transform: translateY(0);
	  }

	}

	.i18n{
	  //transform: translateY(0vh);
	  //transform: translate(-50%, 30vh);
	  //position: fixed;
	  //bottom: 10vh;
	  //left: 50% !important;
	  display: inline-flex;
	  //transition: all .7s ease-in-out ;

	  .lang{
		font-family: $nunito;
		font-size: 20px;
		line-height: 18px;
		text-align: center;

		&:not(:first-child){
		  padding-left: 25px;
		}

		span{
		  line-height: 18px;
		  &.active{
			font-weight: bold;
		  }
		}
	  }
	}

	.line{
	  margin: 33px auto;
	  width: 100%;
	  max-width: 52px;
	  height: 2px;
	  background-color: rgba(20,36,40,0.2);
	}

	a.navbar-button {
	  margin-top: 50px;
	  width: 80vw !important;
	  max-width: 350px;
	  padding: 20px;
	  background-color: $dark;
	  line-height: 20px !important;

	  span {
		color: white;
	  }

	  &.active {
		color: white;
	  }
	}

    .nav-wrapper {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
	  left:0;
      background-color: white;
      padding-top: 56px;
      padding-left: 0;
      padding-right: 0;
      z-index: -1;
	  transform: translateY(100vh);
	  transition: all .6s ease;

      .nav-list {
        text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);

        .nav-item {

          a,
          .submenu-title {
            display: inline-block;
            position: relative;
            text-decoration: none;
            width: 100%;
			color: #142428;
			font-family: $nunito;
			font-size: 20px;
			line-height: 70px;
			text-align: center;
            &.active{
			  font-weight: bold;
            }

          }

		  &:hover{
			a,
			.submenu-title {
			  opacity: 0.5;
			}
		  }
        }
      }
    }

    .nav-menu-button {
      margin: 0;
      padding: 0;
      border: none;
      background: none;
      display: block;
      width: 24px;
      height: 24px;
      @include transform(rotate(0deg));
      @include transition(all 0.5s ease-in-out);
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 50%;
        background: black;
        opacity: 1;
        @include transform(rotate(0deg));
        @include transition(all 0.25s ease-in-out);

        &:nth-child(even) {
          left: 50%;
          @include border-radius(0 20px 20px 0);
        }

        &:nth-child(odd) {
          left: 0;
          @include border-radius(20px 0 0 20px);
        }

        &:nth-child(1),
        &:nth-child(2) {
          top: 0;
        }

        &:nth-child(3),
        &:nth-child(4) {
          top: 8px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          top: 16px;
        }
      }

      &.open {
        span {
          &:nth-child(1),
          &:nth-child(6) {
            @include transform(rotate(45deg));
          }

          &:nth-child(2),
          &:nth-child(5) {
            @include transform(rotate(-45deg));
          }

          &:nth-child(1) {
            left: 0;
            top: 5px;
          }

          &:nth-child(2) {
            left: calc(50% - 4px);
            top: 5px;
          }

          &:nth-child(3) {
            left: -50%;
            opacity: 0;
          }

          &:nth-child(4) {
            left: 100%;
            opacity: 0;
          }

          &:nth-child(5) {
            left: 0;
            top: 13px;
          }

          &:nth-child(6) {
            left: calc(50% - 4px);
            top: 13px;
          }
        }
      }
    }

  }
}

@media (min-width: $md-size) {
  .site-navbar {
    height: 80px;

	&.transparentNav{
	  background-color: transparent;

	  .logo{
		display: none;
	  }

	  .second-logo{
		margin-left: 270px;
		margin-top: 10px;
		width: 82px !important;
		height: 62px;
	  }

	  .nav-wrapper .nav-list {
		> .nav-item{

		  .i18n > .lang > span,
		  a{
			color: #FFFFFF;
			&.active,
			&.hasActiveChild {
			  &:not(.navbar-button){
				color: #FFFFFF;
				opacity: 1;
			  }
			}

		  }

		  .i18n > .lang  {
			&.active{
			  border-bottom: 1px solid white;
			}
		  }

		  .navbar-button{
			transition: all .3s ease;
			background-color: white;
			color: black;
			&:hover{
			  background-color: rgba(255,255,255,0.8);
			}
		  }

		  &:hover{
			a{
			  opacity: 0.5;
			}
		  }

		}
	  }

	}

	&:not(.transparentNav){
	  min-height: 80px;
	  border-bottom: 1px solid #E5E5E5;

	  .nav-wrapper .nav-list {
		.navbar-button{
		  transition: all .3s ease;
		  &:hover{
			background-color: rgba(20,36,40,0.7);
		  }
		}
	  }
	}

	.second-logo{
	  height: 50px;
	  width: 67px;
	  object-fit: contain;
	  margin-left: 52px;
	}

    .nav-wrapper .nav-list {
      > .nav-item {
        display: inline-block;
		&:not(.line){
		  margin-right: 21px;
		}
        position: relative;
		transition: all .7s ease;

        a {
		  color: rgba(20,36,40,0.5);;
		  font-family: $nunito;
		  font-size: 15px;
		  font-weight: 600;
		  line-height: 18px;
		  text-align: center;
          text-decoration: none;
		  transition: all .3s ease-in-out;
		  opacity: 0.7;

          &.active,
          &.hasActiveChild {
			opacity: 1 !important;
			&:not(.navbar-button){
			  color: $blue;
			}
		  }
		}

		.navbar-button{
		  background-color: black;
		  color: white;
		  padding: 8px 19px;
		  opacity: 1 !important;
		  transition: all .7s ease;
		}

        .submenu-title {
          cursor: pointer;

          img {
            margin-left: 5px;
            vertical-align: middle;
          }
        }

        .collapse-wrapper {
          position: absolute;
          top: calc(100% + 25px);
          left: 50%;
          @include transform(translateX(-50%));

          .ReactCollapse--content {
            padding: 0 10px 10px 10px;
          }
        }

        .submenu-wrapper {
          padding-top: 15px;
        }

        .submenu {
          //background-color: $color-background-light;
          padding: 10px 7px;
          min-width: 120px;
          width: -moz-max-content;
          width: max-content;
          text-align: left;
          @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.4));
          position: relative;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: -3px;
            left: 50%;
            width: 21px;
            height: 21px;
            @include transform(rotate(45deg) translateX(-50%));
          }

          &:before {
            @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.4));
            z-index: -1;
          }

          &:after {
            //background: $color-background-light;
          }

          .nav-item {
            &:not(:last-child) {
              margin-bottom: 5px;
            }

            a {
              display: block;
              position: relative;
              z-index: 2;
              font-size: 0.938rem;
              font-weight: 400;
              letter-spacing: 0.1px;
              line-height: 1.533;
              //color: $color-text-grayed;
              text-decoration: none;
              padding: 5px 13px;
              @include transition(all 0.3s ease-in-out);
              //background-color: $color-background-light;
              @include border-radius(2px);

              &:hover {
                //background-color: $color-background-grayed-light-45;
              }

              &.active {
                //color: $color-text-grayed-dark;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .i18n {
      display: block;
      position: relative;
	  opacity: 0.7;
      .lang{
        font-size: 12px;
		font-family: $nunito;
        display: inline-block;
		color: rgba(20, 36, 40, 0.5);
        &:not(:first-child) {
          margin-left: 14px;
        }
        &.active{
		  &.active{
			border-bottom: 1px solid rgba(20, 36, 40, 0.5);
		  }
        }
        span{
          &:not(.active):hover{
            //background-color: $grey-light;
            border-radius: 4px;
            //color: $color-text-grayed-light;
            cursor: pointer;
          }
        }


      }
      .select__control {
        border: none;
        background: none;
        min-height: auto;
        cursor: pointer;
        padding: 5px;

        &.select__control--is-focused {
          @include box-shadow(none);
        }

        .select__indicators {
          display: none;
        }

        .select__value-container {
          padding: 0;
          width: 30px;
          height: 30px;
          background-position: center center;
          background-repeat: no-repeat;
          @include background-size(24px 24px);

          .select__single-value {
            display: none;
          }
        }
      }

      .select__menu {
        @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.2));
        border: none;
        margin-top: 30px;
        width: 120px;
        left: 50%;
        @include transform(translateX(-50%));

        &:before {
          content: '';
          position: absolute;
          top: -3px;
          left: 50%;
          width: 21px;
          height: 21px;
          @include transform(rotate(45deg) translateX(-50%));
          @include box-shadow(0 2px 6px 0 rgba(192, 196, 215, 0.2));
        }

        .select__menu-list {
          padding-left: 4px;
          padding-right: 4px;
          //background: $color-background-light;
          text-align: left;

          .select__option {
            padding: 5px 20px 6px 14px;
            background-color: transparent;
            font-size: 0.938rem;
            font-weight: 400;
            line-height: 1.533;
            letter-spacing: 0.1px;
            //color: $color-text-grayed;
            @include border-radius(2px);
            @include transition(all 0.3s ease-in-out);
            cursor: pointer;

            &:hover,
            &.select__option--is-focused {
              //background-color: $color-background-grayed-light-45;
            }

            &.select__option--is-selected {
              font-weight: 600;
              //color: $color-text-grayed-dark;
            }
          }
        }
      }
    }
  }
}
