@import '../../styles/_variables';
@import '../../styles/_mixins';
@import '../../styles/_animations';

.loading{

  position: fixed;
  z-index: 999999;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  animation-duration: .2s;
  transition-timing-function: ease-in;
  animation-fill-mode: both;
  animation-name: openPage;

  .loader{
	position: relative;
	height: 100%;
	width: 100%;
	.bus,
	.wave{
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  @include transform(translate(-50%,-50%));
	}
	.bus{
	  height: 65.71px;
	  width: 142px;
	  img{
		width: 100%;
		height: 100%;
		animation: float 4s ease-in-out infinite;
	  }
	}
	.wave{
	  top:50%;
	  width: 70%;
	  @include transform(translate(-50%,0%));
	  &:before,
	  &:after {
		position: absolute;
		content: '';
		top: -10px;
		height: 30px;
		width: 75px;
		z-index:1;
	  }
	  &:after{
		right:-3px;
		background-image: linear-gradient(to right, rgba(255,255,255,0) 50%,white);
	  }
	  &:before{
		left:-3px;
		background-image: linear-gradient(to right, white , rgba(255,255,255,0) 50%);

	  }
	  .wave-container {
		overflow: hidden;
	  }
	  .sliding-background {
		background: url("../../assets/images/loading/waves-blue.svg") repeat-x;
		height: 500px;
		width: 10076px;
		animation: slide 60s linear infinite;
	  }
	}
  }
}
