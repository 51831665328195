@import '../../styles/_variables';

.date-picker {
  position: relative;
  width: 100%;

  &.dark {
    background-color: rgba(20, 36, 40, 0.05);
  }

  &.icon {
    .icon-image {
      position: absolute;
      content: '';
      width: 23px;
      height: 23px;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .DateInput {
      .DateInput_input_1 {
        background-color: hotpink;
        padding-left: 45px !important;
      }
    }

    .input-picker {
      margin-left: 40px;
    }
  }
}

.SingleDatePicker {
  width: 100%;
  cursor: pointer;
  transition: background 0.4s ease;
  max-height: 45px;

  &:hover {
    background-color: rgba(20, 36, 40, 0.05);
  }

  .DateInput {
    background: transparent !important;
    width: calc(100% - 40px);

    .DateInput_input {
      padding: 8.5px 10px;
      background: transparent !important;
      border: none;
      color: rgba(20, 36, 40, 0.7);
      font-family: $proxima-nova;
      font-size: 16px;
      font-weight: 500;
      line-height: 27px;
      text-shadow: 0 10px 30px 0 rgba(28, 29, 29, 0.33);
      cursor: pointer;
      text-overflow: ellipsis;
      &::placeholder{
        color: rgba(20, 36, 40, 0.5);
      }
    }
  }

  .SingleDatePickerInput {
    background-color: transparent;
    width: 100%;
    height: 100%;
  }

  .DateInput_fang {
    display: none;
  }

  .SingleDatePickerInput_calendarIcon {
    margin: auto;
    padding-top: 15px;
    padding-left: 0;
    padding-right: 10px;
  }

  .CalendarDay {
    vertical-align: middle;
    border: none;
    font-family: $proxima-nova;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $blue;
    transition: all 0.4s ease;
    &:hover{
      border: none;
    }

    &.CalendarDay__blocked_out_of_range {
      color: rgba(20, 36, 40, 0.2);
    }

    &:not(.CalendarDay__blocked_out_of_range) {
      &.CalendarDay__selected_3,
      &:hover {
        cursor: pointer;
        background-color: $blue;
        color: #ffffff;
      }
    }
  }

  .DayPickerNavigation {
    width: 100%;

    :first-child {
      left: 23px;
    }

    :nth-child(2) {
      right: 23px;
    }
  }

  .DayPickerNavigation_button {
    position: absolute;
    width: 36px;
    top: 14px;
  }

  .CalendarMonth_caption {
    color: #142428;
    font-family: $proxima-nova;
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
    strong {
      font-weight: 500;
    }
  }

  .CalendarMonth_table {
    margin-top: 10px;
  }

  ~:first-child{
    height: inherit;
    max-height: 45px;
  }

}

.input-date-mobile {
  width: 100%;
  padding: 10.5px 8px;
  border: none;
  background-color: #f3f4f4;
  font-size: 16px;
  font-family: $proxima-nova;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  color: rgba(20, 36, 40, 0.7);
}

.DayPicker__withBorder{
  box-shadow: 0 2px 20px 0 rgba(96, 125, 139, 0.18) !important;
}


@media (min-width: $md-size){

  .date-picker{
    &.largeBar{
      .SingleDatePicker_picker{
        top: 69px !important;
        left: -10px !important;
      }
    }
    &.tinyBar{
      .SingleDatePicker_picker{
        top: 59px !important;
        left: -10px !important;
      }
    }
  }

}

input[type='date'] {
  content: attr(placeholder);
}

@media (max-width: 767px) {
  .SingleDatePicker {
    background-color: #f3f4f4;
    text-align: left;
    .DateInput {
      width: calc(100% - 30px);
      .DateInput_input {
        padding-left: 15px !important;
      }
    }

    .SingleDatePickerInput_calendarIcon {
      padding-right: 8px !important;
    }
  }
}

@media (max-width: $md-size) {
  .date-picker {
    &.icon {
      .icon-image {
        left: 8px;
      }
      .input-picker{
        margin-left: 30px;
      }
    }
  }
}

@media (max-width: $sm-size) {
  .date-picker {
    background-color: #f3f4f4;
  }
}
