@import '../../styles/_variables';
@import '../../styles/_animations';
@import '../../styles/_mixins';


.shape-overlays {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;

  .shape-overlays__path:nth-of-type(1) {
	fill: white;
  }
  .shape-overlays__path:nth-of-type(2) {
	fill: $blue;
  }
  .shape-overlays__path:nth-of-type(3) {
	fill: white;
  }
}
