@import '../../node_modules/flexboxgrid/css/flexboxgrid';

.container {
  width: 100%;
  max-width: 1224px;
}

.container-sm {
  max-width: 760px;
}

.container-fluid {
  padding-right: 1rem;
  padding-left: 1rem;
}

.col {
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.gutterless {
  padding-left: 0;
  padding-right: 0;

  > .row {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

[class^='col-xs'],
[class^='col-sm'],
[class^='col-md'],
[class^='col-lg'] {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
}

//@media (max-width: $md-size) {
//  .container-fluid {
//    padding-right: 2rem;
//    padding-left: 2rem;
//  }
//}
